<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToGetFeedbackOnYourWriting",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToGetFeedbackOnYourWriting">
      <SuawParagraph
        text="At some point in your writing journey, you will want to know what other people think about your work. That’s when getting high-quality feedback from trusted readers becomes really important. If you’re wondering who and when to ask for feedback, check out our guide below on how to solicit input on your writing."
      />
      <SuawParagraph text="The feedback cycle is separated into the following phases:" />
      <SuawParagraph text="<img src='https://media.graphassets.com/zwGY2zb4Satctnq9kYsQ' width='100%'>" />
      <SuawHeading level="3" content="1. Yourself" />
      <SuawParagraph
        text="One of the strongest readers for your work is actually you! You can use the following techniques to experience your own writing from the perspective of a reader:"
      />
      <SuawParagraph
        text="Wait a few days before reading your work in order to get some distance from your perspective as the author. Some authors are able to gain this perspective in a matter of days, whereas others prefer to wait for much longer, like the famous Stephen King, who lets his drafts sit for six weeks before revisiting them."
      />
      <SuawParagraph
        text="Read your piece out loud or copy sections of it into a <a target='_blank' title='https://ttsreader.com/' href='https://ttsreader.com/'>“Text to Speech” reader</a> for a fun way to hear your writing read out loud. Note that text readers use pre-loaded voices and may sound robotic, but they can still get the job done."
      />
      <SuawParagraph
        text="Print it out or load it onto your e-reader to see it outside of the software you normally use to write, so you can switch perspectives and see the story through the eyes of a reader rather than the eyes of a writer."
      />
      <SuawHeading level="3" content="2. Alpha Readers" />
      <SuawParagraph
        text="Alpha readers typically consist of close friends and family who are happy to support you in your writing. Depending on their personality and the level of feedback you’ve asked for, you can get responses ranging from unbridled encouragement to in-depth critical analysis."
      />
      <SuawParagraph
        text="It’s important to set expectations with your alpha readers about the kind of input you’re looking for. Many a vulnerable first draft has met harsh words of critique too early in the process. One way to ensure that you don’t get comments that send you reeling is to be gentle on yourself and your draft early in the process. Ask your alpha readers for insight on the bigger picture, like whether your theme is coming across, or how they would describe your narrative voice, and address those elements first. Be sure to let them know that you may not be ready for detailed, line-by-line critiques."
      />
      <SuawHeading level="3" content="3. Beta &amp; Sensitivity Readers" />
      <SuawParagraph
        text="After you’ve addressed your alpha reader’s feedback, you can then move on to finding beta readers. Beta readers are readers experienced with your genre. These readers don’t necessarily know you personally, and are therefore more likely to provide an unbiased take."
      />
      <SuawParagraph
        text="Sensitivity readers are a specific type of beta reader who will read your work with particular emphasis on unintentional biases and stereotypes you may have accidentally written in. If you’re working with characters who differ from you in race, gender, and/or sexuality, it is especially advisable to consider working with sensitivity readers."
      />
      <SuawParagraph text="If you’d like to learn more about finding a beta reader, check out our in-depth guide here." />
      <SuawHeading level="3" content="4. Professional Editors" />
      <SuawParagraph
        text="Finally, you may decide to work with professional editors to get that final level of expert feedback. Professional editing is roughly divided into three categories: Developmental editing, copyediting and proofreading. Knowing which kind of editor you need for your piece is vital to getting the feedback you need. Learn more about when to bring in one of these experts, how much their services cost, and how to determine a good fit in our in-depth guide about editors."
      />
      <SuawParagraph
        text="Whether you decide to go through all four of these phases or not, know that feedback will make your writing stronger, and over time, you’ll get better at sifting through input and deciding how to use it. Ultimately, you are the guardian of your own writing, so you get to decide which comments to take and which to discard."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
